import { Checkbox } from "antd";
import "./ProductCard.scss";
import { isNullOrUndefined } from "utils/commonFunctions";
import {
  BriefCaseIcon,
  CodeIcon,
  ColorSwatchIcon,
  CopyInactiveIcon,
  LayerIcon,
  TrashInactiveIcon,
} from "components/Icons";
import { useDispatch } from "react-redux";
import productSlice from "../../app/productSlice";
import { useTranslation } from "react-i18next";

const ProductCard = (props: any) => {
  const {
    product,
    setActiveProduct = () => null,
    activeProduct,
    isViewMode = false,
    isLatest,
    isShowIcon = true,
    width,
  } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onClickTrashIcon = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(
      productSlice.actions.changeProductsInfo({
        productId: product.id,
        checked: false,
      })
    );
  };

  const onClickCheckbox = (e: any) => {
    e.stopPropagation();
    dispatch(
      productSlice.actions.changeProductsInfo({
        productId: product.id,
        checked: e.target.checked,
      })
    );
  };

  return (
    <div
      className="m-bt-12 m-x-12 cursor-pointer product-card-container"
      style={{
        border: activeProduct?.id === product.id ? "2px solid #FDB913" : "",
        marginBottom: isLatest ? "60px" : "",
        width: width,
      }}
      onClick={() => {
        if (isViewMode) {
          return;
        } else {
          setActiveProduct(product);
          dispatch(productSlice.actions.setActiveProduct(product));
        }
      }}
    >
      <div className="top-product-info">
        {isShowIcon && (
          <div className="product-type">
            {product.product_type?.product_type_name}{" "}
            {isViewMode ? (
              <TrashInactiveIcon onClick={onClickTrashIcon} />
            ) : (
              <Checkbox checked={product.checked} onClick={onClickCheckbox} />
            )}
          </div>
        )}
        <div className="product-name">{product.product_name}</div>
        <div className="product-id-tsc">
          <div className="product-id m-r-8">
            <CopyInactiveIcon copiedValue={product.id} />
            <div className="m-l-4">#{product.id}</div>
          </div>
          <div className="product-id">
            <CodeIcon />
            <div className="m-l-4">{product.tsc || t("notSpecified")}</div>
          </div>
        </div>
      </div>
      <div className="bottom-product-info">
        <div>
          <BriefCaseIcon />
          {product.customer.customer_name}
        </div>
        <div>
          <ColorSwatchIcon />
          {product.dim_width} x {product.dim_height} x {product.dim_length}
        </div>
        <div>
          <LayerIcon />
          {isNullOrUndefined(product.inner_dim_width) ? (
            <span className="m-l-4">{t("notSpecified")}</span>
          ) : (
            <span className="m-l-4">
              {product.inner_dim_width} x {product.inner_dim_height} x{" "}
              {product.inner_dim_length}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
